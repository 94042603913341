import type { ReactElement } from 'react'

export default function Cal({
  className,
}: {
  className?: string
}): ReactElement {
  return (
    <svg
      className={className}
      viewBox="0 0 80 18"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M7.967 16.487C3.423 16.487 0 12.891 0 8.45 0 3.995 3.248.37 7.967.37c2.505 0 4.238.769 5.593 2.53l-2.185 1.82c-.918-.976-2.025-1.465-3.408-1.465-3.073 0-4.763 2.354-4.763 5.195 0 2.842 1.85 5.151 4.763 5.151 1.369 0 2.534-.488 3.452-1.465l2.155 1.894c-1.296 1.688-3.073 2.457-5.607 2.457zM22.983 4.661h2.942v11.545h-2.942V14.52c-.611 1.199-1.631 1.998-3.583 1.998-3.116 0-5.607-2.708-5.607-6.039 0-3.33 2.49-6.039 5.607-6.039 1.938 0 2.972.8 3.583 1.999V4.66zm.088 5.817c0-1.805-1.238-3.3-3.19-3.3-1.879 0-3.102 1.51-3.102 3.3 0 1.747 1.223 3.3 3.102 3.3 1.937 0 3.19-1.509 3.19-3.3zM28.008 0h2.942v16.192h-2.942V0zM32.262 14.666c0-.947.757-1.746 1.791-1.746 1.034 0 1.763.8 1.763 1.746 0 .977-.743 1.776-1.763 1.776s-1.791-.799-1.791-1.776zM47.073 14.341c-1.093 1.332-2.753 2.176-4.72 2.176-3.51 0-6.087-2.708-6.087-6.039 0-3.33 2.578-6.039 6.088-6.039 1.893 0 3.539.8 4.631 2.058l-2.272 1.91c-.568-.711-1.31-1.244-2.36-1.244-1.878 0-3.102 1.51-3.102 3.3 0 1.791 1.224 3.301 3.103 3.301 1.136 0 1.922-.592 2.505-1.391l2.214 1.968zM47.32 10.478c0-3.33 2.578-6.039 6.088-6.039 3.51 0 6.088 2.709 6.088 6.04 0 3.33-2.578 6.038-6.088 6.038-3.51-.015-6.088-2.708-6.088-6.039zm9.19 0c0-1.805-1.223-3.3-3.102-3.3-1.879-.015-3.102 1.495-3.102 3.3 0 1.791 1.224 3.3 3.102 3.3 1.88 0 3.103-1.509 3.103-3.3zM79.39 9.147v7.045h-2.942v-6.32c0-1.998-.932-2.857-2.33-2.857-1.31 0-2.243.652-2.243 2.857v6.32h-2.942v-6.32c0-1.998-.947-2.857-2.33-2.857-1.311 0-2.462.652-2.462 2.857v6.32H61.2V4.647h2.942v1.599c.612-1.244 1.719-1.865 3.423-1.865 1.617 0 2.971.8 3.714 2.146.743-1.376 1.835-2.146 3.816-2.146 2.418.015 4.296 1.85 4.296 4.766z"
        fill="currentColor"
      />
    </svg>
  )
}
