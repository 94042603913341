import type { FC } from 'react'

import s from './LoadingDots.module.css'

interface Props {
  className?: string
}

const LoadingDots: FC<Props> = ({ className }) => {
  return (
    <span className={s.root}>
      <span className={className} />
      <span className={className} />
      <span className={className} />
    </span>
  )
}

export default LoadingDots
