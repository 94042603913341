import { XMarkIcon } from '@heroicons/react/20/solid'
import * as Dialog from '@radix-ui/react-dialog'
import { Canvas } from '@react-three/fiber'
import { EffectComposer, HueSaturation } from '@react-three/postprocessing'
import cn from 'classnames'
import type { FieldProps, FormikTouched } from 'formik'
import { Field, Form, Formik } from 'formik'
import { DefaultSeo, NextSeo } from 'next-seo'
import Head from 'next/head'
import Link from 'next/link'
import { BlendFunction } from 'postprocessing'
import React, { useEffect, useState } from 'react'
import type { FC, ReactNode } from 'react'
import type { TypeOf } from 'zod'
import { object, string } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import seoConfig from '@assets/config/seo'
import Wave from '@components/home/Wave'
import AngelList from '@components/icons/logos/AngelList'
import Cal from '@components/icons/logos/Cal'
import Twitter from '@components/icons/logos/Twitter'
import LoadingDots from '@components/ui/LoadingDots/LoadingDots'
import usePrefersReducedMotion from '@lib/hooks/use-reduced-motion'
import type { PageProps } from '@motif/shared/pages/generate-static-props'
import type { TemplateProps } from '@motif/shared/pages/template'
import { getTemplate } from '@motif/shared/pages/template'

const requestAccessFormSchema = object({
  firstName: string({ required_error: 'Please enter your first name' }),
  lastName: string({ required_error: 'Please enter your last name' }),
  email: string().email('Please enter a valid email'),
  website: string().optional(),
  contentWriters: string(),
})

type RequestAccessFormInputs = TypeOf<typeof requestAccessFormSchema>

const salesFormSchema = object({
  firstName: string({ required_error: 'Please enter your first name' }),
  lastName: string({ required_error: 'Please enter your last name' }),
  email: string().email('Please enter a valid email'),
  message: string({ required_error: 'Please enter a message' }),
})

type SalesFormInputs = TypeOf<typeof salesFormSchema>

type CustomFieldProps = {
  name: string
  type: string
  as?: string | React.ComponentType<FieldProps['field']>
  placeholder?: string
  className: string
  touched: FormikTouched<any>
  errors: any
  setFieldError: (name: string, message: string | undefined) => void
  setFieldTouched: (name: string, touched: boolean) => void
  disabled: boolean
}

const CustomField: FC<CustomFieldProps> = ({
  name,
  type,
  as,
  placeholder,
  className,
  touched,
  errors,
  setFieldError,
  setFieldTouched,
  disabled,
}) => {
  return (
    <Field
      type={type}
      name={name}
      as={as}
      placeholder={placeholder}
      disabled={disabled}
      className={className}
      data-error={touched[name] && errors[name]}
      onFocus={() => {
        setFieldError(name, undefined)
        setFieldTouched(name, false)
      }}
    />
  )
}

const FormSubmitButton = ({ isSubmitting }: { isSubmitting: boolean }) => {
  return (
    <button
      type="submit"
      disabled={isSubmitting}
      className={cn(
        'ring-offset-3 relative select-none overflow-hidden rounded-md border-0 bg-white px-3 py-2 text-sm font-medium text-neutral-900 outline-none ring-sky-500 ring-offset-neutral-900 transition hover:ring focus:ring disabled:cursor-not-allowed disabled:bg-neutral-800 disabled:ring-0'
      )}
    >
      <span
        className={cn({
          'opacity-0': isSubmitting,
          'opacity-100': !isSubmitting,
        })}
      >
        Submit
      </span>
      <div
        className={cn(
          'absolute inset-0 z-10 flex items-center justify-center',
          {
            'opacity-100': isSubmitting,
            'opacity-0': !isSubmitting,
          }
        )}
      >
        <LoadingDots className={cn('bg-neutral-300')} />
      </div>
    </button>
  )
}

const submitForm = async (
  formId: string,
  values: any,
  resetForm: () => void,
  setSubmitting: (submitting: boolean) => void,
  setThanks: (submitting: boolean) => void,
  setOpen: (submitting: boolean) => void
) => {
  setSubmitting(true)
  await fetch(`https://formspree.io/f/${formId}`, {
    method: 'POST',
    body: JSON.stringify(values),
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
  })
  setTimeout(() => {
    // Leave for an extra second, then hide form before
    // resetting the form.
    setThanks(true)
    setTimeout(() => {
      setSubmitting(false)
      setTimeout(() => {
        setOpen(false)
        setTimeout(() => {
          resetForm()
          setThanks(false)
        }, 1000)
      }, 2000)
    }, 1000)
  }, 1000)
}

const RequestAccessDialog = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (open: boolean) => void
}) => {
  const [thanks, setThanks] = useState(false)

  useEffect(() => {
    if (open) {
      setThanks(false)
    }
  }, [open])

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="animate-overlay fixed inset-0 z-20 bg-black/50 backdrop-blur-xl" />
        <Dialog.Content className="animate-dialog-slide-in dialog-content hiddenScrollbar relative flex max-h-[90%] w-[90%] max-w-[560px] flex-col p-8">
          <div
            className={cn(
              'pointer-events-none absolute inset-0 flex transform items-center justify-center p-8 text-sm delay-700 duration-700',
              {
                '-translate-y-2 opacity-100': thanks,
                'translate-y-0 opacity-0': !thanks,
              }
            )}
          >
            <p className="text-center text-neutral-300">Thank you!</p>
          </div>
          <div
            className={cn('relative transform duration-500', {
              'pointer-events-none translate-y-4 opacity-0': thanks,
            })}
          >
            <Dialog.Title className="flex-grow text-lg font-medium text-white">
              Join the waitlist
            </Dialog.Title>
            <Dialog.Description className="mt-2 text-sm text-neutral-500">
              By submitting this information, you will be included in our
              waitlist. Once ready, we will promptly contact you to initiate the
              setup process. Want a head start? Sign up for{' '}
              <a
                className="home-a"
                href="https://markprompt.com"
                target="_blank"
                rel="noreferrer"
              >
                Markprompt
              </a>
              , which will eventually merge with Motif.
            </Dialog.Description>
            <Formik<RequestAccessFormInputs>
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                website: '',
                contentWriters: 'yes',
              }}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={toFormikValidationSchema(
                requestAccessFormSchema
              )}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                await submitForm(
                  'xnqkqzpr',
                  values,
                  resetForm,
                  setSubmitting,
                  setThanks,
                  setOpen
                )
              }}
            >
              {({
                isSubmitting,
                touched,
                setFieldTouched,
                errors,
                setFieldError,
              }) => (
                <Form className="home-form mt-8">
                  <div className="grid grid-cols-6 items-center gap-x-4 gap-y-2">
                    <label className="col-span-3">First name</label>
                    <label className="col-span-3">Last name</label>
                    <CustomField
                      name="firstName"
                      type="text"
                      className="col-span-3"
                      placeholder="Ava"
                      disabled={isSubmitting}
                      touched={touched}
                      errors={errors}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                    />
                    <CustomField
                      name="lastName"
                      type="text"
                      className="col-span-3"
                      placeholder="Davis"
                      disabled={isSubmitting}
                      touched={touched}
                      errors={errors}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                    />
                    <label className="col-span-6">Company email</label>
                    <CustomField
                      name="email"
                      type="email"
                      className="col-span-6"
                      placeholder="ava@example.com"
                      disabled={isSubmitting}
                      touched={touched}
                      errors={errors}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                    />
                    <label className="col-span-6">
                      Do you have a live docs website?{' '}
                      <span className="font-normal text-neutral-600">
                        If so, please indicate below
                      </span>
                    </label>
                    <CustomField
                      name="website"
                      type="text"
                      className="col-span-6"
                      placeholder="docs.example.com"
                      disabled={isSubmitting}
                      touched={touched}
                      errors={errors}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                    />
                    <label className="col-span-6">
                      Are there content writers on your team?
                    </label>
                    <div className="col-span-6 flex flex-row items-center gap-8 text-base">
                      <label className="flex flex-row items-center gap-2">
                        <Field
                          type="radio"
                          name="contentWriters"
                          value="Yes"
                          disabled={isSubmitting}
                        />
                        <span className="font-normal">Yes</span>
                      </label>
                      <label className="flex flex-row items-center gap-2">
                        <Field
                          type="radio"
                          name="contentWriters"
                          value="No"
                          disabled={isSubmitting}
                        />
                        <span className="font-normal">No</span>
                      </label>
                    </div>
                  </div>
                  <div className="mt-12 flex flex-row-reverse items-center">
                    <FormSubmitButton isSubmitting={isSubmitting} />
                    <div className="flex-grow" />
                    <a
                      href="https://twitter.com/markprompt"
                      target="_blank"
                      rel="noreferrer"
                      className="home-border-button flex flex-row items-center gap-2"
                    >
                      <Twitter className="-ml-1 h-5 w-5" />
                      <span>Follow updates</span>
                    </a>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Dialog.Close asChild>
            <button
              className="home-icon-button absolute top-3 right-3"
              aria-label="Close"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

const ContactSalesDialog = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (open: boolean) => void
}) => {
  const [thanks, setThanks] = useState(false)

  useEffect(() => {
    if (open) {
      setThanks(false)
    }
  }, [open])

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="animate-overlay fixed inset-0 z-20 bg-black/50 backdrop-blur-xl" />
        <Dialog.Content className="animate-dialog-slide-in dialog-content hiddenScrollbar relative flex max-h-[90%] w-[90%] max-w-[560px] flex-col p-8">
          <div
            className={cn(
              'pointer-events-none absolute inset-0 flex transform items-center justify-center p-8 text-sm delay-700 duration-700',
              {
                '-translate-y-2 opacity-100': thanks,
                'translate-y-0 opacity-0': !thanks,
              }
            )}
          >
            <p className="text-center text-neutral-300">Thank you!</p>
          </div>
          <div
            className={cn('relative transform duration-500', {
              'translate-y-4 opacity-0': thanks,
            })}
          >
            <Dialog.Title className="flex-grow text-lg font-medium text-white">
              Contact our sales team
            </Dialog.Title>
            <Dialog.Description className="mt-2 text-sm text-neutral-500">
              Contact our sales team to learn more about our product and secure
              early access. We are eager to provide you with information and
              grant you access to experience our product firsthand.
            </Dialog.Description>
            <Formik<SalesFormInputs>
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                message: '',
              }}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={toFormikValidationSchema(salesFormSchema)}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                await submitForm(
                  'xvojobaz',
                  values,
                  resetForm,
                  setSubmitting,
                  setThanks,
                  setOpen
                )
              }}
            >
              {({
                isSubmitting,
                touched,
                setFieldTouched,
                errors,
                setFieldError,
              }) => (
                <Form className="home-form mt-8">
                  <div className="grid grid-cols-6 items-center gap-x-4 gap-y-2">
                    <label className="col-span-3">First name</label>
                    <label className="col-span-3">Last name</label>
                    <CustomField
                      name="firstName"
                      type="text"
                      className="col-span-3"
                      placeholder="Harper"
                      disabled={isSubmitting}
                      touched={touched}
                      errors={errors}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                    />
                    <CustomField
                      name="lastName"
                      type="text"
                      className="col-span-3"
                      placeholder="Evans"
                      disabled={isSubmitting}
                      touched={touched}
                      errors={errors}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                    />
                    <label className="col-span-6">Company email</label>
                    <CustomField
                      name="email"
                      type="email"
                      className="col-span-6"
                      placeholder="harper.evans@example.com"
                      disabled={isSubmitting}
                      touched={touched}
                      errors={errors}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                    />
                    <label className="col-span-6">Message</label>
                    <CustomField
                      name="message"
                      type="text"
                      as="textarea"
                      placeholder="Tell us about your needs and timeline"
                      className="col-span-6 h-40 min-h-[38px]"
                      disabled={isSubmitting}
                      touched={touched}
                      errors={errors}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                    />
                  </div>
                  <div className="mt-6 flex justify-end">
                    <FormSubmitButton isSubmitting={isSubmitting} />
                  </div>
                </Form>
              )}
            </Formik>
            <div className="mt-6 flex flex-row items-center gap-4 border-t border-neutral-900 pt-6">
              <p className="flex-grow text-xs text-neutral-500">
                Want to skip the form and speak with an expert?
              </p>
              <a
                href="https://cal.com/markprompt/motifdemo"
                target="_blank"
                rel="noreferrer"
                className="home-border-button flex-none"
              >
                Book a demo
              </a>
            </div>
          </div>
          <Dialog.Close asChild>
            <button
              className="home-icon-button absolute top-3 right-3"
              aria-label="Close"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

const Home: FC<PageProps> & {
  isPublicHome: boolean
  getTemplate: (
    page: React.ReactNode,
    templateProps: TemplateProps
  ) => JSX.Element
} = () => {
  const [animated, setAnimated] = useState(true)
  const [joinButtonHover, setJoinButtonHover] = useState(false)
  const [requestDialogOpen, setRequestDialogOpen] = useState(false)
  const [salesDialogOpen, setSalesDialogOpen] = useState(false)
  const prefersReducedMotion = usePrefersReducedMotion()

  useEffect(() => {
    const handleVisibilityChange = () => {
      setAnimated(document.visibilityState === 'visible')
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return (
    <>
      <Head>
        <title>Motif · Copilot for Docs</title>
        <meta
          name="description"
          content="Expert tools and APIs to maintain technical content at scale, powered by AI."
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <DefaultSeo {...seoConfig} />
      <NextSeo
        openGraph={{ ...seoConfig.openGraph, url: 'https://motif.land' }}
      />
      <div className="home relative flex h-screen w-full items-center justify-center bg-gradient-to-br from-neutral-900 to-[#050505] antialiased">
        <div className="to-neutral-950 fixed inset-0 z-0 h-screen w-full bg-[#050505] bg-gradient-to-br from-neutral-900" />
        <div className="home-dots fixed inset-0 h-screen w-full" />
        <div
          className="fade-in-slide-down absolute inset-x-0 top-0 flex justify-center px-8 py-8 sm:py-12"
          style={{
            animationDelay: '700ms',
          }}
        >
          <div className="flex w-full max-w-[900px] flex-row items-center gap-2 sm:gap-2">
            <p className="flex flex-none flex-row items-center gap-3 text-xs ">
              <span className="text-white">Motif</span>
              <span className="text-neutral-600">|</span>
              <span className="text-neutral-600">technical preview</span>
            </p>
            <div className="flex-grow" />
            <Link
              className="home-ghost-button hidden flex-none sm:block"
              href="/login"
            >
              Member login
            </Link>
            <button
              className="home-ghost-button flex-none"
              onClick={() => {
                setSalesDialogOpen(true)
              }}
            >
              Contact sales
            </button>
            <a
              className="home-icon-button"
              href="https://twitter.com/markprompt"
              aria-label="Follow Markprompt on Twitter"
              target="_blank"
              rel="noreferrer"
            >
              <Twitter className=" h-5 w-5" />
            </a>
          </div>
        </div>
        <div className="z-10 -mt-32 flex flex-col items-center px-8 sm:-mt-24">
          <h1 className="fade-in-slide-down home-gradient-text mb-8 text-center text-[72px] font-semibold leading-none tracking-tighter sm:text-[100px]">
            Copilot for docs
          </h1>
          <p
            className="fade-in-slide-up mb-12 text-center text-xl text-neutral-200 sm:text-2xl"
            style={{
              animationDelay: '200ms',
            }}
          >
            Expert tools and APIs to maintain technical content at scale,
            powered by AI
          </p>
          <div
            className="fade-in-slide-up-long"
            style={{
              animationDelay: '400ms',
            }}
          >
            <button
              className="select-none rounded-lg border-0 bg-white px-5 py-3 font-medium text-neutral-900 outline-none ring-sky-500 ring-offset-4 ring-offset-neutral-900 transition hover:ring focus:ring"
              onClick={() => {
                setRequestDialogOpen(true)
              }}
              onMouseEnter={() => {
                setJoinButtonHover(true)
              }}
              onMouseLeave={() => {
                setJoinButtonHover(false)
              }}
            >
              Join the waitlist
            </button>
          </div>
        </div>
        <div className="fade-in-slide-up fixed inset-x-0 bottom-0 z-0 h-[65%] transform duration-500">
          <Canvas
            camera={{
              position: [
                0.1912020407052579, -4.037434449482079, 1.8383531942382878,
              ],
            }}
          >
            <ambientLight color="white" intensity={0.5} />
            <directionalLight color="white" position={[0.5, 0, 0.866]} />
            <Wave
              showPoints={!joinButtonHover}
              animate={animated && !prefersReducedMotion}
            />
            <EffectComposer multisampling={8}>
              <HueSaturation
                blendFunction={BlendFunction.NORMAL}
                hue={0}
                saturation={0.8}
              />
            </EffectComposer>
          </Canvas>
        </div>
        <div
          className="fade-in-slide-up fixed inset-x-0 bottom-0 hidden h-[80px] items-center justify-center gap-12 bg-white px-8 py-4"
          style={{
            animationDelay: '400ms',
          }}
        >
          <AngelList className="w-[82px] text-neutral-400 transition duration-300 hover:text-neutral-500" />
          <Cal className="w-[82px] text-neutral-400 transition duration-300 hover:text-neutral-500" />
        </div>
      </div>
      {/* Cover canvas boundaries to prevent glitches */}
      <div
        className={cn('pointer-events-none fixed inset-0 z-20 transition', {
          'opacity-0': !requestDialogOpen && !salesDialogOpen,
          'opacity-100': requestDialogOpen || salesDialogOpen,
        })}
      >
        <div className="fixed inset-x-0 bottom-0 h-40 bg-gradient-to-t from-neutral-900 to-neutral-900/0"></div>
        <div className="fixed inset-y-0 left-0 w-40 bg-gradient-to-r from-neutral-900 to-neutral-900/0"></div>
        <div className="fixed inset-y-0 right-0 w-40 bg-gradient-to-l from-neutral-900 to-neutral-900/0"></div>
      </div>
      <RequestAccessDialog
        open={requestDialogOpen}
        setOpen={setRequestDialogOpen}
      />
      <ContactSalesDialog open={salesDialogOpen} setOpen={setSalesDialogOpen} />
    </>
  )
}

Home.isPublicHome = true
Home.getTemplate = getTemplate

export default Home
