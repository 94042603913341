import type { ReactElement } from 'react'

export default function Twitter({
  className,
}: {
  className?: string
}): ReactElement {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="none"
    >
      {/* <path
        d="M18 1.684l-1.687 1.684v.28c0 .307-.05.602-.123.886-.04 2.316-.777 5.387-3.816 7.81C6.404 17.115 0 12.907 0 12.907c5.063 0 5.063-1.684 5.063-1.684-1.126 0-3.376-2.243-3.376-2.243.563.56 1.689 0 1.689 0C.56 7.295.56 5.61.56 5.61c.563.561 1.689 0 1.689 0C-.563 3.368 1.124.561 1.124.561 1.687 3.368 9 4.49 9 4.49l.093-.046A6.637 6.637 0 0 1 9 3.368C9 1.353 10.636 0 12.656 0c1.112 0 2.094.506 2.765 1.286l.329-.163L17.437 0l-1.122 2.245L18 1.684z"
        fillRule="nonzero"
      ></path> */}
      <path
        d="M21 6.684l-1.687 1.684v.28c0 .307-.05.602-.123.886-.04 2.316-.777 5.387-3.816 7.81C9.404 22.115 3 17.907 3 17.907c5.063 0 5.063-1.684 5.063-1.684-1.126 0-3.376-2.243-3.376-2.243.563.56 1.689 0 1.689 0-2.816-1.685-2.816-3.37-2.816-3.37.563.561 1.689 0 1.689 0-2.812-2.242-1.125-5.049-1.125-5.049C4.687 8.368 12 9.49 12 9.49l.093-.046A6.633 6.633 0 0112 8.368C12 6.353 13.636 5 15.656 5c1.112 0 2.094.506 2.765 1.286l.329-.163L20.437 5l-1.122 2.245L21 6.684z"
        fill="currentColor"
      />
    </svg>
  )
}
